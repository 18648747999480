<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/faultReport' }">故障上报</el-breadcrumb-item>
          <el-breadcrumb-item>故障详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-title">故障上报信息</div>
          <div class="table-list">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="水表编号：">{{form.waterMeterCode}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="水表状态：">{{form.result==0?'正常':form.result==1?'异常':'-'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="上报人员：">{{form.reportPerson}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="上报手机号：">{{form.phone}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="上报时间：">{{form.reportTime ? dayjs(form.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-'}}</el-form-item>
              </el-col>
            </el-row>
          </div>

          <div class="form-title">故障上报反馈：<span>{{ form.result==0?'正常':form.result==1?'异常':'-' }}</span></div>
          <div style="border:1px solid #EBEEF5;padding:20px;margin-bottom:20px;" v-show="form.result==1">
            <el-checkbox-group v-model="form.abnormalList">
                <el-checkbox v-for="(item, index) in feedbackData" :key="index" :label="item.id" disabled>{{item.attributeContent}}</el-checkbox>
            </el-checkbox-group>
          </div>

          <div class="form-title">上报图片</div>
          <div v-if="form.fileUrls.length>0" class="img-box">
            <el-image 
              class="images"
              v-for="item in form.fileUrls"
              :key="item"
              :src="item"
              :preview-src-list="[item]">
            </el-image>
            <!-- <img v-for="item in form.deviceArchivesImages" :key="item.id" :src="item.url" /> -->
          </div>
          <div v-else style="margin: 0 0 30px 15px;font-size:14px;">（无）</div>

          <div class="form-title">用户台账信息</div>
          <div class="table-list">
            <el-table :data="userData" style="width: 100%">
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="userCode" label="用户编号"></el-table-column>
                <el-table-column prop="userName" label="用户名称"></el-table-column>
                <el-table-column prop="userPhone" label="用户手机"></el-table-column>
                <el-table-column prop="holderPhone" label="户主手机"></el-table-column>
                <el-table-column prop="waterAddress" label="用水地址"></el-table-column>
                <el-table-column prop="population" label="家庭人口"></el-table-column>
                <el-table-column prop="idType" label="证件类型">
                  <template slot-scope="scope">
                        {{ scope.row.idType=='ID-CARD'?'身份证':'-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="idNumber" label="证件号码"></el-table-column>
                <el-table-column prop="regionName" label="所属片区"></el-table-column>
                <el-table-column prop="zoneName" label="所属册本"></el-table-column>
                <el-table-column prop="userStatus" label="用户状态">
                    <template slot-scope="scope">
                      <span v-show="scope.row.userStatus=='working'">在户</span>
                      <span v-show="scope.row.userStatus=='stopping'">停户</span>
                      <span v-show="scope.row.userStatus=='written'">注销</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createRecordTime" label="建档时间">
                    <template slot-scope="scope">
                    {{ scope.row.createRecordTime ? dayjs(scope.row.createRecordTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                    </template>
                </el-table-column>
                <el-table-column prop="remarks" label="备注"></el-table-column>
            </el-table>
          </div>

          <div class="form-title">水表台账信息</div>
          <div class="table-list">
            <el-table :data="waterMeterData" style="width: 100%">
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="userCode" label="用户编号"></el-table-column>
                <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
                <el-table-column prop="userName" label="用户名称"></el-table-column>
                <el-table-column prop="address" label="水表地址"></el-table-column>
                <el-table-column prop="useProperty" label="用水性质"></el-table-column>
                <el-table-column prop="originPlace" label="水表产地"></el-table-column>
                <el-table-column prop="type" label="水表类型"></el-table-column>
                <el-table-column prop="caliber" label="水表口径"></el-table-column>
                <el-table-column prop="model" label="水表型号"></el-table-column>
                <el-table-column prop="valve" label="阀门状态"></el-table-column>
                <el-table-column prop="readType" label="抄表方式"></el-table-column>
                <el-table-column prop="capacity" label="水表容量"></el-table-column>
                <el-table-column prop="watchCase" label="表壳号"></el-table-column>
                <el-table-column prop="coreNum" label="表芯号"></el-table-column>
                <el-table-column prop="runningStatus" label="水表状态"></el-table-column>
                <el-table-column prop="position" label="安装位置"></el-table-column>
                <el-table-column prop="createTime" label="安装时间">
                  <template slot-scope="scope">
                    {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
                  </template>
                </el-table-column>
            </el-table>
          </div>

          <!-- <div class="form-title">处理问题</div>
          <div style="margin: 0 0 5px 15px;font-size:14px;">处理说明：</div>
          <div style="border:1px solid #EBEEF5;padding:20px;margin-bottom:20px;font-size:14px;">
            无内容
          </div>
          <div style="margin: 0 0 5px 15px;font-size:14px;">处理结果：</div>
          <div style="border:1px solid #EBEEF5;padding:20px;margin-bottom:20px;font-size:14px;">
            <span v-show="form.processingResult==0">未解决</span>
            <span v-show="form.processingResult==1">已解决</span>
            <span v-show="form.processingResult==2">误报</span>
          </div>
          
          <div class="img-box">
            <el-image 
              class="images"
              v-for="item in form.deviceArchivesImages"
              :key="item.url"
              :src="item.url"
              :preview-src-list="[item.url]">
            </el-image>
            <img v-for="item in form.deviceArchivesImages" :key="item.id" :src="item.url" />
          </div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="处理人员：">{{form.processingPersonName?form.processingPersonName:'（无）'}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="处理时间：">
                  {{ form.processingTime?dayjs(form.processingTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="定位位置：">陕西省西安市</el-form-item>
            </el-col>
          </el-row> -->
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/faultReport')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false,
      faultData: [
        {
            id: '001',
            status: 0,
            number: '0015463',
            region: '城东-A区',
            upName: '李煜',
            upPhone: '15246879456',
            upTime: 1637128834515
        }
      ],
      feedbackData: [],
      userData: [],
      waterMeterData: [],
    };
  },
  methods: {
    dayjs,
    loadPropertyRight() {
      this.$ajax.post('meterAttrQuery',{
        attributeCode: 'waterMeterReportAbnormal'
      }).then(res => {
        this.feedbackData = res.data
      })
    },
    loadData() {
      this.loading = true
      this.$ajax.post("faulReportDetail", {
        id: this.row.id,
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.userData.push(res.data.waterMeterUserBook)
        this.waterMeterData.push(res.data.waterMeterBook)
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/faultReport')
		} else {
      this.row = this.$route.params.row
    }
    this.loadData()
    this.loadPropertyRight()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.form-box {
    max-width: 1500px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.table-list {
  margin-bottom: 20px;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>
<style lang="less">
</style>
